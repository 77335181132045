<template>
    <div class="order-mask" v-if="dataVal.flag">
        <!-- 添加订单 -->
        <div class="mask-main">
            <div class="mask-nav">
                <div></div>
                <div class="mask-title">账号设置</div>
                <i class="el-icon-close" @click="handleRefund"></i>
            </div>
            <div class="mask-content">
                <div class="cenbox">
                    <div class="cen">
                        <div class="cen_head">
                            <div class="cen_xian"></div>
                            <div class="cen_title">基本资料</div>
                        </div>
                        <div class="cen_body cen-table">
                            <!-- <el-input v-model="userobj.username" placeholder="请输入用户名"></el-input> -->
                            <table>
                                <tr>
                                    <td class="cen-tabtd">登录账号</td>
                                    <td><el-input v-model="userobj.username" placeholder="请输入登录账号"></el-input></td>
                                    <td class="cen-tabtd">联系人姓名</td>
                                    <td><el-input v-model="userobj.name" placeholder="请输入联系人姓名"></el-input></td>
                                </tr>
                                <tr>
                                    <td class="cen-tabtd">联系人电话</td>
                                    <td>
                                        <el-input v-model="userobj.phone" maxlength="11" type="number"
                                            placeholder="请输入联系人电话"></el-input>
                                    </td>
                                    <td class="cen-tabtd">店铺名称</td>
                                    <td><el-input v-model="userobj.shopname" placeholder="请输入店铺名称"></el-input></td>
                                </tr>
                                <tr>
                                    <td class="cen-tabtd">店铺地址</td>
                                    <td><el-input v-model="userobj.shopaddr" placeholder="请输入店铺地址"></el-input></td>
                                    <td class="cen-tabtd">详细地址</td>
                                    <td><el-input v-model="userobj.shopdetailaddr" placeholder="请输入详细地址"></el-input>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="cenbox">
                    <div class="cen">
                        <div class="cen_head">
                            <div class="cen_xian"></div>
                            <div class="cen_title">登录密码</div>
                        </div>
                        <div class="cen_body cen-table">
                            <table>
                                <tr>
                                    <td class="cen-tabtd">登录密码</td>
                                    <td><el-input v-model="userobj.password" placeholder="请输入登录密码"></el-input></td>
                                    <td class="cen-tabtd">再次确认密码</td>
                                    <td><el-input v-model="reppassword" placeholder="请输入确认密码"></el-input></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mask-footer">
                <button @click="handleRefund">{{ dataVal.btns }}</button>
                <button class="btn-bg" @click="handleSubmit">{{ dataVal.btnq }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ErpIntegration",
    props: ['dataVal'],
    data() {
        return {
            userobj: {
                username: '',
                name: '',
                phone: '',
                shopname: '',
                shopaddr: '',
                shopdetailaddr: '',
                password: '',
            },
            reppassword: '',
        };
    },
    mounted() {
    },
    methods: {
        async indexGetinfo() {
            let res = await this.$api.indexGetinfo()
            if (res.code == 1) {
                this.userobj = res.data
                this.userobj = {
                    username: res.data.username ? res.data.username : '',
                    name: res.data.realname ? res.data.realname : '',
                    phone: res.data.phone ? res.data.phone : '',
                    shopname: res.data.shopname ? res.data.shopname : '',
                    shopaddr: res.data.shopaddr ? res.data.shopaddr : '',
                    shopdetailaddr: res.data.shopdetailaddr ? res.data.shopdetailaddr : '',
                    password: '',
                }
            }
        },
        //取消弹窗
        handleRefund() {
            let data = {
                flag: false,
                submitFlag: false
            }
            this.$emit("settingsCall", data);
        },
        handleSubmit() {
            let repuserobj = JSON.parse(JSON.stringify(this.userobj))
            if ((this.userobj.password != '' && this.userobj.password != null) && this.reppassword != this.userobj.password) {
                this.$message.error('登录密码与确认密码不一致');
                return false;
            }
            let data = {
                flag: false,//弹窗是否显示
                submitFlag: true,//是否调用接口
                obj: repuserobj
            }
            this.$emit("settingsCall", data);
        },
    },
    watch: {
        dataVal: {
            handler(newValue, oldValue) {
                if (newValue.flag) {
                    this.indexGetinfo()
                }
            },
            deep: true
        }
    }
};
</script>

<style lang="less" scoped>
div,
table,
tr,
td {
    box-sizing: border-box;
}

.order-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    .mask-main {
        width: 40vw;
        padding: 0;
        background-color: #fff;
        border-radius: 1.296vh 0.521vw 0.926vh 0.521vw;

        .mask-nav {
            height: 2.6042vw;
            background-color: #066ff8;
            display: flex;
            align-content: center;
            justify-content: space-between;
            border-bottom: 0.185vh solid #ECECEC;
            padding: 0 .7813vw;

            i {
                font-size: 1.25vw;
                cursor: pointer;
            }

            .mask-title {
                font-size: 0.833vw;
                font-weight: 400;
                color: #fff;
                display: flex;
                align-items: center;

                div {
                    margin-right: 0.521vw;
                    width: 0.208vw;
                    height: 1.481vh;
                    background: #066ff8;
                }
            }

            .el-icon-close {
                color: #fff;
                display: flex;
                align-items: center;
            }
        }
    }

    .mask-content {
        width: 100%;
        background-color: #fff;
        padding: 0 .7813vw;

        .cenbox {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .cen-table {
                margin: 0;

                table {
                    width: 100%;
                    text-align: center;
                    border: 0.052vw solid #E1E1E1;
                    border-collapse: collapse;
                    color: #666666;
                    display: flex;
                    flex-wrap: wrap;
                    flex-shrink: 0;

                    tr {
                        width: 100%;
                        display: flex;
                        flex-shrink: 0;
                    }

                    td {
                        border: 0.052vw solid #E1E1E1;
                        flex: 1;
                        box-sizing: border-box;
                        height: 5.185vh;
                        line-height: 5.185vh;
                    }

                    input {
                        width: 99%;
                        min-height: 5.185vh;
                        text-align: center;
                        font-size: 0.833vw;
                        border: 0;
                        outline: none;
                        color: #333;
                    }

                    .cen-tabtd {
                        width: 5.2083vw;
                        flex: none;
                        background-color: #F8F8F8;
                        font-weight: bold;
                        color: #333;
                    }
                }

            }

            .cen {
                flex: 1;
                width: 100%;
                height: auto;
                background: #FFFFFF;
                border-radius: 1.296vh 0.521vw 0.926vh 0.521vw;

                .cen_head {
                    display: flex;
                    align-items: center;
                    padding: .5208vw 0;

                    .cen_xian {
                        margin-right: 0.521vw;
                        width: 0.208vw;
                        height: 0.833vw;
                        background-color: #2c72ff;
                    }
                }

                /deep/.cen_body {
                    width: 100%;
                    border: .0521vw solid #d7d7d7;
                    border-radius: .2604vw;
                    display: flex;
                    align-items: center;

                    .el-input {
                        .el-input__inner {
                            border: 0;
                            font-size: .833vw;
                        }
                    }
                }

            }

            .cen:nth-of-type(2) {
                margin-left: .7813vw;
            }
        }
    }

    .mask-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5625vw;
        margin-bottom: .7813vw;

        button {
            width: 7.813vw;
            height: 4.259vh;
            background-color: #ffffff;
            border-radius: 0.313vw;
            border: solid 0.052vw #2c72ff;
            color: #2c72ff;
            margin-right: 2.708vw;
            font-size: 0.729vw;
            cursor: pointer;
        }

        button {
            background: #fff;
            border: 0.052vw solid #066ff8;
            color: #066FF8;
            margin-right: 3.125vw;
        }

        button:last-child {
            margin-right: 0;
        }

        .btn-bg {
            background: #066ff8;
            border: 0.052vw solid #066ff8;
            color: #fff;
        }
    }
}
</style>